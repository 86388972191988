<template>
  <div class="right">
    <div class="title titleborder">
      {{ form.title }}
      <div class="rightreleaseTime">发布时间：{{ form.releaseTime }}</div>
    </div>
    <div v-html="form.content"></div>
  </div>
</template>

<script>
import {getArticleDetail } from "@/api/dynamicsOfCollegeEntranceExamination.js";
export default {
  data() {
    return {
      form: {}, //文章详情
    };
  },
  watch:{
      $route(val){
          if(val.name == "dynamicsOfCollegeEntranceExaminationdetial"){
              console.log(val,'val');
              let id = this.$route.query.id
              this.getdetail(id)
          }
      }
  },
  mounted() {
    let id = this.$route.query.id
    this.getdetail(id)
  },
  methods: {
    getdetail(id) {
      getArticleDetail({ id: id,type:4 }).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.form = res.data || {};
          this.isflag = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.right {
  width: 940px;
  padding: 0 30px;
  box-sizing: border-box;
  .title {
    width: 910px;
    // height: 106px;
    font-size: 26px;
    color: #222222;
    line-height: 106px;
    text-align: center;
    padding-bottom: 30px;
  }
  .titleborder{
    position: relative;
    width: 910px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 30px;
  }
  .rightreleaseTime{
  position: absolute;
  bottom: 60px;
  right: 0px;
  font-size: 14px;
  height: 20px;
  color: #999999;
  }
}
</style>
